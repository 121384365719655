import React from 'react';

import ManStand from "../../assets/images/why-choose-us-image/man-stand.png";
import Database from "../../assets/images/why-choose-us-image/database.png";
import CercleShape from "../../assets/images/services-left-image/cercle-shape.png";
import MainStatic from "../../assets/images/why-choose-us-image/main-static.png";

const WhyChoose = () => {
    return (
        <div className="why-choose-us ptb-80 pt-0">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="section-title">
                            <h2>Our Core Values</h2>
                            <div className="bar"></div>
                            <p>Our value-driven organizational culture is a driving force behind all our exceptional work and achievements. This ensures the well-being of our team, leading to extreme productivity and successful project delivery.</p>
                        </div>

                        <div className="why-choose-us-image">
                            <img 
                                src={ManStand}
                                className="animate__animated animate__fadeInLeft" 
                                alt="image"
                            />
                    
                            <img 
                                src={Database}
                                className="animate__animated animate__fadeInRight" 
                                alt="image"
                            />
                
                            <img 
                                src={CercleShape}
                                className="rotateme" 
                                alt="image"
                            />
                
                            <img 
                                src={MainStatic}
                                className="main-pic animate__animated animate__fadeInUp" 
                                alt="image"
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="single-why-choose-us">
                                    <div className="icon">
                                        <i className="flaticon-team"></i>
                                    </div>
                                    <h3>Customer-Centric</h3>
                                    <p>We prioritize our customers' needs and strive to provide them with the best experience.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="single-why-choose-us">
                                    <div className="icon">
                                        <i className="flaticon-rocket"></i>
                                    </div>
                                    <h3>Innovation & Creativity</h3>
                                    <p>We foster a culture of innovation and creativity, constantly seeking better ways to improve ourselves.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="single-why-choose-us">
                                    <div className="icon">
                                        <i className="flaticon-shield"></i>
                                    </div>
                                    <h3>Integrity</h3>
                                    <p>We hold integrity as a core value, prioritizing honesty, transparency, and ethical behavior in all our actions and decisions.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="single-why-choose-us">
                                    <div className="icon">
                                        <i className="flaticon-diamond"></i>
                                    </div>
                                    <h3>Excellence</h3>
                                    <p>We strive for excellence in everything we do, setting high standards and continuously pushing ourselves to achieve the best possible outcomes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;
