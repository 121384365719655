import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/NavbarStyleThree";
//import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Partner from "../components/Common/Partner";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import OurValues from '../components/Hosting/WhyChoose';

import SocialResponsibility from '../components/BigdataAnalytics/Feedback';

import AboutOne from "../assets/images/about-one.png";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";

const About1 = () => (
    <Layout>
        
        <NavbarStyleThree/>
        <SEO title="About Us" /> 

        <PageBanner pageTitle="About Us" />


        <div className="about-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={AboutOne} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>About Us</h2>
                                <div className="bar"></div>
                                <p>At Ratoon, we specialize in providing AI and automation solutions to help businesses streamline their operations, reduce costs, and increase efficiency.</p>
                            </div>

                            <p>Our team of experienced professionals is dedicated to helping our clients achieve their goals by leveraging the latest technologies and best practices.</p>

                            <p>We believe that the future of business lies in the use of AI and automation to optimize processes, improve decision-making, and deliver better customer experiences.</p>

                            <p>KISS  (Keep It Simple, Stupid) principle is our strength ! As our tagline "Being Futuristic" says we will always try to be prepared and relevant to the digital evolution.</p>
                        </div>
                    </div>
                </div>

                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>It all started in 2017 as an eCommerce service provider. Our market study, research, and experience have led us to recognize the need of the hour to create bots.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Our mission is to empower businesses to leverage the full potential of AI and Automation and stay ahead of the curve in an ever-changing business landscape.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Who We Are</h3>
                                <p>We feed intelligence to bots and deploy those to perform repetitive tasks and enhance decision-making capabilities for your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br /><br /><br /><br />

        <OurValues />

        {/*<Team />*/}

        <Partner />

        <SocialResponsibility />

        {/* <FunFactsArea /> */}
        
        <Footer />
    </Layout>
)

export default About1;
